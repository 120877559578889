.c-sidebar {
    z-index: z(header);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    &__toggle {
        z-index: z(header, toggle);
        position: relative;
        display: flex;
        width: 2.625rem;
        height: 100%;
        flex-direction: column;
        background-color: white;
        border-right: 1px #E1E1E1 solid;
        transition: background-color 250ms $easeOut, border-right 250ms $easeOut;

        .no-touch &:hover {
            background-color: #F7F7F7;
            cursor: pointer;
        }

        &.v--menu {
            border-right: 1px #333 solid;
            background-color: $color__blackish;
        }

        .no-touch &.v--menu:hover {
            background-color: darken($color__blackish, 1%);
        }
    }

    &__logo {
        height: 6rem;

        &-meade {
            position: absolute;
            top: 1.25rem;
            left: 0.75rem;
            width: 0.9375rem;
        }
        &-head {
            position: absolute;
            top: 1.25rem;
            left: 0.625rem;
            width: 1.375rem;
            display: none;
        }

        svg {
            circle,
            path,
            polygon,
            rect {
                transition: fill 250ms $easeOut;
                fill: $color__blackish;
            }
        }

        &.v--white {
            svg {
                circle,
                path,
                polygon,
                rect {
                    fill: white;
                }
            }
        }
    }

    &__hamburger {
        flex: 1;

        &-layers {
            position: absolute;
            top: 50%;
            left: 0.625rem;
            margin-top: -4rem;
        }
    }

    &__overlay {
        z-index: z(header, overlay);
        display: none;
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color__blackish, 0.65);
    }

    &__menu {
        z-index: z(header, menu);
        position: absolute;
        height: 100%;
        top: 0;

        @include breakpoint($breakpoint-sm) {
            padding: 1rem 1.75rem 1rem 1.75rem;
            width: 20rem;
            left: (-20rem + 2.625rem);
        }

        @include breakpoint($breakpoint-md) {
            padding: 2rem 3rem 2rem 3rem;
            width: 25rem;
            left: (-25rem + 2.625rem);
        }

        @include breakpoint($breakpoint-lg) {
            padding: 2rem 3.5rem 2rem 3.5rem;
            width: 30rem;
            left: (-30rem + 2.625rem);
        }

        &.v--menu {
            left: 2.625rem;
            display: block;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: $color__blackish;
                }
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .h3 {
            color: white;
        }

        &-separator {
            width: 4rem;
            height: 0.5rem;
            background-color: $color__white;
            @include border-radius(0.25rem);

            @include breakpoint($breakpoint-sm) {
                margin-bottom: 2.5rem;
                margin-top: 2.5rem;
            }

            @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
                margin-bottom: 2.875rem;
                margin-top: 2.875rem;
            }
        }

        &-group {
            margin-top: 2rem;
        }

        &-label {
            font-weight: $font-bold__weight;
            font-size: 0.75em;
            letter-spacing: $font-uppercase__letter-spacing;
            text-transform: uppercase;
            color: white;
            line-height: 2;
        }

        &-txt {
            color: #D1D1D1;
            font-size: 1.1rem;
            line-height: 1.125;

            a {
                color: inherit;
                transition: 300ms cubic-bezier(.785,.135,.15,.86);

                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    top: 1px;
                    width: 0;
                    height: 100%;
                    right: 0;
                    left: auto;
                    border-bottom: 1px $color__yellow solid;
                    transition: 300ms cubic-bezier(.785,.135,.15,.86);
                }

                .no-touch &:hover {
                    color: white;

                    &::before {
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }

        @include breakpoint(map-get($mq, sm-landscape)) {

            .h3 {
                font-size: 1.5rem;
                margin-bottom: 2.25rem;
            }

            &-separator {
                display: none;
            }

            &-group {
                margin-top: 1.125rem;
            }

            &-label {
                line-height: 1.4;
            }
        }
    }

    &__bg-wave {
        display: none;
    }
}