.e-dl {
    display: flex;

    > * {
        padding-right: 3rem;

        @include breakpoint($breakpoint-sm) {
            padding-right: 1.5rem;
        }

        @include breakpoint($breakpoint-md) {
            padding-right: 2rem;
        }
    }

    > *:nth-child(2),
    > *:nth-child(3) {
        border-left: 1px #DDD dotted;
        padding-left: 1.5rem;

        @include breakpoint($breakpoint-sm) {
            padding-left: 1rem;
        }
    }

    > *:nth-child(3) {
        padding-right: 0;
    }

    dt {
        @include font-family($font-dt__family, $font-dt__family-default);
        @include font-weight($font-dt__weight, $font-dt__weight-default);
        line-height: $font-dt__line-height;
        color: $font-dt__color;
        letter-spacing: $font-dt__letter-spacing;
        margin-bottom: 0.5rem;
        text-transform: uppercase;

        @include breakpoint($breakpoint-sm) {
            font-size: $font-dt__size--sm;
        }

        @include breakpoint($breakpoint-md) {
            font-size: $font-dt__size--md;
        }

        @include breakpoint($breakpoint-lg) {
            font-size: $font-dt__size--lg;
        }
    }

    dd {
        @include font-family($font-dl__family, $font-dl__family-default);
        @include font-weight($font-dl__weight, $font-dl__weight-default);
        line-height: $font-dl__line-height;
        color: $font-dl__color;
        letter-spacing: $font-dl__letter-spacing;
        min-width: 3rem;

        @include breakpoint($breakpoint-sm) {
            font-size: $font-dl__size--sm;
            min-width: 2.5rem;
        }

        @include breakpoint($breakpoint-md) {
            font-size: $font-dl__size--md;
            min-width: 0.5rem;
        }

        @include breakpoint($breakpoint-lg) {
            font-size: $font-dl__size--lg;
        }
    }
}