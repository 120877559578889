.c-btn-next {
    width: 2.6rem;
    height: 2.6rem;
    display: block;
    transition: transform 150ms $easeOut, width 150ms $easeOut, height 150ms $easeOut, margin 150ms $easeOut;
    background-color: $color__pink;
    @include border-radius(50%);

    .no-touch &:hover {
        width: 3rem;
        height: 3rem;
        margin-top: -0.2rem;
        margin-left: -0.2rem;
        margin-bottom: -0.2rem;
        cursor: pointer;
    }

    &__arrow {
        position: absolute;
        top: 55%;
        left: 50%;
        width: 0.5rem;
        transform: translateX(-40%) translateY(-50%);
        transition: transform 150ms $easeOut;

        svg {
            width: inherit;
            height: auto;

            path {
                fill: white;
            }
        }
    }

    .no-touch &:hover &__arrow {
        transform: translateX(-40%) translateY(-52%) rotate(-45deg);
    }

    .no-touch &.v--down:hover &__arrow {
        transform: translateX(-40%) translateY(-50%) rotate(45deg);
    }
}
