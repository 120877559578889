.u-txt {

    &--white {
        color: white !important;
    }

    &--anthracite {
        color: $color__anthracite !important;
    }

    &--red {
        color: $color__pink !important;
    }

    &--pink {
        color: $color__pink !important;
    }

    &--green {
        color: $color__green !important;
    }

    &--yellow {
        color: $color__yellow !important;
    }

    @each $breakpoint-name, $breakpoint in $breakpoints {
    	@include breakpoint($breakpoint) {

            &--white\@#{$breakpoint-name} {
                color: white !important;
            }

            &--anthracite\@#{$breakpoint-name} {
                color: $color__anthracite !important;
            }
        }
    }
}