/*
 * Based on https://github.com/jonsuh/hamburgers
 */

$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0.25rem;
$hamburger-layer-width         : 1.25rem;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : $color__grey-dark;
$hamburger-layer-color-hover   : $color__anthracite;
$hamburger-layer-border-radius : 5px;

.c-hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: block;

    // Box
    &__box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    // Inner
    &__inner {
        display: block;
        top: 50%;
        margin-top: $hamburger-layer-height / -2;

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            position: absolute;
            @include border-radius($hamburger-layer-border-radius);
        }

        &::before,
        &::after {
            content: '';
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            width: $hamburger-layer-width - 0.25rem;
            margin-left: 0.25rem;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            width: $hamburger-layer-width - 0.5rem;
            margin-left: 0.5rem;
        }
    }

    // Hover
    &.v--hover &__inner {
        &,
        &::before,
        &::after {
            background-color: $hamburger-layer-color-hover;
        }

        &::before {
            width: $hamburger-layer-width;
            margin-left: 0rem;
        }
        &::after {
            width: $hamburger-layer-width;
            margin-left: 0rem;
        }
    }

    &.v--white &__inner {
        &,
        &::before,
        &::after {
            background-color: #E1E1E1;
        }
    }

    &.v--white.v--hover &__inner {
        &,
        &::before,
        &::after {
            background-color: white;
        }
    }

    // Effect = Spring
    & &__inner {
      top: $hamburger-layer-height / 2;

      &::before {
        top: $hamburger-layer-height + $hamburger-layer-spacing;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 150ms $easeIn, margin 150ms $easeIn;
      }

      &::after {
        top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 150ms $easeIn, margin 150ms $easeIn;
      }
    }

    // Effect = Spring
    &.v--cross &__inner {
        background-color: transparent !important;

        &::before {
            top: 0;
            transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), width 150ms $easeIn, margin 150ms $easeIn;
            transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
            width: $hamburger-layer-width;
            margin-left: 0rem;
        }

        &::after {
            top: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), width 150ms $easeIn, margin 150ms $easeIn;
            transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
            width: $hamburger-layer-width;
            margin-left: 0rem;
        }
    }
}