.c-btn-rounded {
    display: table;
    border: 2px solid #DDD;
    color: $color__white;
    text-transform: uppercase;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: $font-uppercase__letter-spacing;
    font-size: 0.65em;
    font-weight: $font-bold__weight;
    z-index: 1;
    transition: border-color 150ms 150ms $easeOut, color 150ms 150ms $easeOut, background-color 299ms 1ms;
    overflow: hidden;
    @include border-radius(1.375rem);

    &::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        right: 0;
        left: auto;
        background-color: $color__pink;
        transition: 300ms cubic-bezier(.785,.135,.15,.86);

    }

    .no-touch &:hover {
        border-color: $color__pink;
        cursor: pointer;
        background-color: $color__pink;
        transition: border-color 150ms 150ms $easeOut, color 150ms 150ms $easeOut, background-color 1ms 299ms;

        &::before {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}