// Colors
$color-white: #FFFFFF;
$color-black: #000000;

// Shapes
$padding-square: 100%;
$padding-rect-16-9: 56.25%;
$padding-rect-16-10: 62.5%;
$padding-rect-4-3: 75%;
$padding-rect-3-2: 66.66%;
$padding-rect-2-1: 50%;
$padding-rect-1-2: 200%;
$padding-rect-9-16: 177.77%;
$padding-rect-10-16: 160%;
$padding-rect-3-4: 133.33%;
$padding-rect-2-3: 150%;
$padding-circle: 100%;