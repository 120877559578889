body {
    background-color: #EEE;
    min-width: 21.875rem;
    overscroll-behavior-y: none;
    -webkit-font-smoothing: antialiased;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-moz-selection { /* Code for Firefox */
    color: $color__white;
    background: $color__pink;
}

::selection {
    color: $color__white;
    background: $color__pink;
}