.c-btn-contact {
    display: table;
    color: $color__anthracite;
    background-color: white;
    text-transform: uppercase;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    line-height: 2.75rem;
    letter-spacing: $font-uppercase__letter-spacing;
    font-size: 0.7em;
    font-weight: $font-bold__weight;
    transition: background-color 150ms $easeIn, color 150ms $easeIn;
    @include border-radius(0.125rem);

    .no-touch &:hover {
        color: $color__white;
        background-color: $color__pink;
        cursor: pointer;
    }
}