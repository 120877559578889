// Images - Wrappers
picture,
.img__wrapper,
.img--fit-wrapper {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

// Images - Position
img,
.img {
    display: block;

	// Width 100%
	&.v--width-100p,
	&--width-100p {
		width: 100%;
		height: auto;
	}

	// Height 100%
	&.v--height-100p,
	&--height-100p {
		width: auto;
		height: 100%;
	}

	// Fill
	&.v--fill,
	&--fill {
		object-fit: cover;
		font-family: 'object-fit: cover;';
	}

	// Fit
	&.v--fit,
	&--fit {
		object-fit: contain;
		font-family: 'object-fit: contain;';
	}

	// Fill & Fit
	&.v--fill,
	&.v--fit,
	&--fill,
	&--fit {
    	top: 0;
	    left: 0;
    	position: absolute;
    	width: 100%;
	    height: 100%;
	    overflow: hidden;
    }

    &.v--top,
	&--top {
		object-position: 50% 0;
		font-family: 'object-position: 50% 0;';
	}

    &.v--left-top,
    &.v--top-left,
    &--left-top,
	&--top-left {
		object-position: 0 0;
		font-family: 'object-position: 0 0;';
	}

    &.v--right-top,
    &.v--top-right,
    &--right-top,
	&--top-right {
		object-position: 100% 0;
		font-family: 'object-position: 100% 0;';
	}

    &.v--bottom,
    &--bottom {
		object-position: 50% 100%;
		font-family: 'object-position: 50% 100%;';
	}

    &.v--left-bottom,
    &.v--bottom-left,
    &--left-bottom,
	&--bottom-left {
		object-position: 0 100%;
		font-family: 'object-position: 0 100%;';
	}

    &.v--right-bottom,
    &.v--bottom-right,
    &--right-bottom,
	&--bottom-right {
		object-position: 100% 100%;
		font-family: 'object-position: 100% 100%;';
	}

    &.v--center,
	&--center {
		object-position: 50% 50%;
		font-family: 'object-position: 50% 50%;';
	}

    &.v--left,
	&--left {
		object-position: 0 50%;
		font-family: 'object-position: 0 50%;';
	}

    &.v--right,
	&--right {
		object-position: 100% 50%;
		font-family: 'object-position: 100% 50%;';
	}
}