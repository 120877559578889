.c-section-banner {

    @include breakpoint($breakpoint-sm) {
        margin-bottom: -4rem;
    }
    
    @include breakpoint($breakpoint-md) {
        margin-top: 3rem;
        margin-bottom: -4rem;
    }
    
    @include breakpoint($breakpoint-lg) {
        margin-top: 3rem;
        margin-bottom: -5rem;
    }
}