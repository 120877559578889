/**
 * CDN
 */
$cdn-root: '';
@if ($env == 'production') {
    $cdn-root: '//christophemeade.com/assets';
} @else {
    $cdn-root: '//christophemeade.com.loc/assets';
}
$cdn-img: $cdn-root + '/img';
$cdn-js:  $cdn-root + '/js';
$cdn-css: $cdn-root + '/css';
$cdn-fonts: $cdn-root + '/fonts';

/**
 * Fonts Loaded
 */
$class-loaded: 'fonts-loaded';

/**
 * REM
 */
$rem-baseline: 16px;
$rem-fallback: false;

/**
 * Colors
 */
$color__white: #fff;
$color__black: #000;
$color__blackish: #202020;
$color__pink: #fb3044;
$color__yellow: #fac200;
//$color__blue: #6eb8c3;
$color__green: #54d1b7;
$color__anthracite: #2E3843;

$color__blue: #DCE8ED;
$color__blue-light: #edf3f6;

/**
 * Colors - Txt
 */
$color__txt: #686b71;
$color__txt-grey: #8b8e95;
$color__txt-dark: #333;
$color__title: #111;

/**
 * Colors - Grey
 */
$color__grey: #F3F3F3;
$color__grey-dark: #666;
$color__grey-darker: #333;

/**
 * Media Queries
 */
$mq: (

    // Orientation
    portrait:       (orientation portrait),
    landscape:      (orientation landscape),

    // Device
    print:          print,
    screen:         screen,

    // Breakpoints
    sm:             (max-width 50em),
    sm-portrait:    (max-width 50em) (orientation portrait),
    sm-landscape:   (max-width 50em) (orientation landscape),

    md:             (50.0625em 64em),
    md-portrait:    (50.0625em 64em) (orientation portrait),
    md-landscape:   (50.0625em 64em) (orientation landscape),

    lg:             (64.0625em),
    lg-portrait:    (64.0625em) (orientation portrait),
    lg-landscape:   (64.0625em) (orientation landscape),

    xlg:            (85em),
    xlg-portrait:   (85em) (orientation portrait),
    xlg-landscape:  (85em) (orientation landscape)
);

/**
 * Breakpoints used to generate Breakpoint min & max values
 */
$breakpoints: (
    sm: map-get($mq, sm),
    md: map-get($mq, md),
    lg: map-get($mq, lg)
);

/**
 * Breakpoints
 */
$breakpoint-sm: map-get($mq, sm);
$breakpoint-md: map-get($mq, md);
$breakpoint-lg: map-get($mq, lg);
$breakpoint-xlg: map-get($mq, xlg);

/**
 * Z-Indexes
 */
$z-indexes: (

    index: (
        intro: (
            slideshow,
            sections
        )
    ),

    // Scroll
    scroll: (),

    // Header
    header: (
        overlay,
        menu,
        toggle
    ),

    // Progress
    progress: (),

    // Welcome
    welcome: (
        intro: (
            placeholder,
            bg,
            imgIn,
            placeholderBtn,
            overlay

        ),
        img: (),
        howdy: (
            bg,
            topography,
            blob,
            section,
            header
        ),
        loader: (),

        border: (),
        slogan: (),
        c: (),
        wave: ()
    ),

    // Mousetrack
    mousetrack: ()
);

/**
 * Additional
 */
@import "settings.fonts";
@import "settings.components";
