.c-btn-stylish {
    @include font-weight($font-bold__weight, $font-bold__weight-default, $class-loaded);
    font-size: 0.7em;
    text-transform: uppercase;
    letter-spacing: $font-uppercase__letter-spacing;
    padding-left: 2.25rem;
    padding-right: 0.5rem;
    color: $color__anthracite;
    transition: padding 250ms $easeInOut;
    display: table;

    &::before {
        content: '';
        position: absolute;
        width: 1.5rem;
        height: 1px;
        top: 0.65rem;
        left: 0;
        background-color: $color__anthracite;
        transition: width 250ms $easeInOut;
    }

    .no-touch &:hover {
        color: $color__anthracite;
        padding-left: 2.75rem;
        padding-right: 0;
        cursor: pointer;

        &::before {
            width: 2rem;
        }
    }
}