.js-textBanner {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;

    &__item {
        display: inline-block;
    }

    /*
     * JS
     */
    &.v--hidden {
        opacity: 0;
    }
}