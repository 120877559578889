.c-section-reverse {
    display: flex;

    @include breakpoint($breakpoint-sm) {
        justify-content: flex-end;
    }

    &__negative {
        
        @include breakpoint($breakpoint-sm) {
            display: none;
        }
        
        @include breakpoint($breakpoint-md) {
            flex: 0 1 23rem;
        }
        
        @include breakpoint($breakpoint-lg) {
            flex: 0 1 31rem;
        }
    }

    &__txt {
        background-color: $color__anthracite;
        @include border-radius(0.25rem);

        &-highlight {
            text-transform: uppercase;
            font-weight: $font-bold__weight;
            font-size: 0.65em;
            letter-spacing: $font-uppercase__letter-spacing;
            color: white;
            line-height: 1;
            padding-left: 2.5rem;

            &-separator {
                width: 1.5rem;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -2px;

                > div {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                }
            }
        }

        @include breakpoint($breakpoint-sm) {
            padding: 3rem 2rem 3rem 2rem;
            margin-left: 1.75rem;
            margin-right: 1.75rem;
            margin-top: -1.75rem;

            h5 {
                margin-top: 1.5rem;
                margin-bottom: 2rem;
            }
        }

        @include breakpoint($breakpoint-md) {
            padding: 4rem;
            max-width: 30rem;
            margin-top: -4rem;

            h5 {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        @include breakpoint($breakpoint-lg) {
            padding: 4rem;
            max-width: 32rem;
            margin-top: -4rem;

            h5 {
                margin-top: 2.25rem;
                margin-bottom: 2.25rem;
            }
        }
    }
}