.js-textBanner {

    &__item {

        @include breakpoint($breakpoint-sm) {
            padding-right: 3rem;
        }

        @include breakpoint($breakpoint-md) {
            padding-right: 4rem;
        }

        @include breakpoint($breakpoint-lg) {
            padding-right: 5rem;
        }
    }
}