.c-section {
    display: flex;

    &__negative {
        @include breakpoint($breakpoint-sm) {
            display: none;
        }
        @include breakpoint($breakpoint-md) {
            flex: 0 0 25rem;
        }
        @include breakpoint($breakpoint-lg) {
            flex: 0 0 33rem;
        }
    }

    &__txt {

        @include breakpoint($breakpoint-sm) {
            padding: 5rem 1.75rem 5rem 1.75rem;
            max-width: 30rem;
            margin-left: auto;
            margin-right: auto;

            dl {
                margin-top: 2.5rem;
            }

            .p {
                margin-top: 1.5rem;
            }
        }

        @include breakpoint($breakpoint-md) {
            padding: 6rem 0rem 6rem 0;
            width: 22.5rem;

            dl {
                margin-top: 3rem;
            }

            .p {
                margin-top: 1.75rem;
            }
        }

        @include breakpoint($breakpoint-lg) {
            padding: 7rem 0rem 7rem 0rem;
            width: 26rem;

            dl {
                margin-top: 3rem;
            }

            .p {
                margin-top: 2.25rem;
            }
        }
    }
}