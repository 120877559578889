.o-shape {
	width: 100%;
	display: block;
	position: relative;

	> *:not(.o-shape--ignore) {
		position: absolute;
		left: 0;
        right: 0;
        top: 0;
        bottom: 0;
	}

	&:after {
		display: block;
		content: '';
	}

	// Overflow Hidden
	&.v--overflow-hidden > * {
    	overflow: hidden;
	}

    // Square
	&.v--square:after {
		padding-top: $padding-square;
	}

    // Rectangle - 16:9
 	&.v--rect-16\:9:after {
		padding-top: $padding-rect-16-9;
	}

	// Rectangle - 16:10
 	&.v--rect-16\:10:after {
		padding-top: $padding-rect-16-10;
	}

    // Rectangle - 4:3
	&.v--rect-4\:3:after {
		padding-top: $padding-rect-4-3;
	}

    // Rectangle - 2:1
	&.v--rect-2\:1:after {
		padding-top: $padding-rect-2-1;
	}

    // Rectangle - 3:2
	&.v--rect-3\:2:after {
		padding-top: $padding-rect-3-2;
	}

    // Rectangle - 1:2
	&.v--rect-1\:2:after {
		padding-top: $padding-rect-1-2;
	}

    // Rectangle - 9:16
	&.v--rect-9\:16:after {
		padding-top: $padding-rect-9-16;
	}

	// Rectangle - 10:16
	&.v--rect-10\:16:after {
		padding-top: $padding-rect-10-16;
	}

    // Rectangle - 3:4
	&.v--rect-3\:4:after {
		padding-top: $padding-rect-3-4;
	}

    // Rectangle - 2:3
	&.v--rect-2\:3:after {
		padding-top: $padding-rect-2-3;
	}

    // Circle
	&.v--circle {
    	&:after {
    		padding-top: $padding-circle;
    	}
		> * {
			overflow: hidden;
			@include border-radius(50%);
		}
	}

    // Responsive
    @each $breakpoint-name, $breakpoint in $breakpoints {
    	@include breakpoint($breakpoint) {

            // Square
        	&.v--square\@#{$breakpoint-name}:after {
        		padding-top: $padding-square;
        	}

            // Rectangle - 16:9
        	&.v--rect-16\:9\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-16-9;
        	}

        	// Rectangle - 16:10
        	&.v--rect-16\:10\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-16-10;
        	}

            // Rectangle - 4:3
        	&.v--rect-4\:3\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-4-3;
        	}

            // Rectangle - 2:1
        	&.v--rect-2\:1\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-2-1;
        	}

            // Rectangle - 1:2
        	&.v--rect-1\:2\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-1-2;
        	}

            // Rectangle - 9:16
        	&.v--rect-9\:16\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-9-16;
        	}

        	// Rectangle - 10:16
        	&.v--rect-10\:16\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-10-16;
        	}

            // Rectangle - 3:4
        	&.v--rect-3\:4\@#{$breakpoint-name}:after {
        		padding-top: $padding-rect-3-4;
        	}

            // Circle
        	&.v--circle\@#{$breakpoint-name} {
            	&:after {
            		padding-top: $padding-circle;
            	}
        		> * {
        			overflow: hidden;
        			@include border-radius(50%);
        		}
        	}
        }
    }
}