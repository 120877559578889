.c-reveal {
    position: absolute;
    display: none;
    width: calc(100% + 0.125rem);
    height: calc(100% + 0.5rem);
    top: -0.125rem;
    left: 0;
    background-color: $color__grey;
    z-index: 10;

    &.v--dark {
        background-color: lighten($color__anthracite, 4%);
    }

    &.v--visible {
        display: block;
    }

    &.v--round {
        @include border-radius(50%);
    }
}