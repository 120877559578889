.c-intro {
    display: flex;

    @include breakpoint($breakpoint-sm) {
        flex-direction: column;
    }

    @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
        min-height: 28rem;
        height: 100vh;

        .viewport-loaded & {
            height: calc(100 * var(--vh));
        }
    }

    &__sections {
        z-index: z(index, intro, sections);

        @include breakpoint($breakpoint-md) {
            flex: 0 0 25rem;
        }

        @include breakpoint($breakpoint-lg) {
            flex: 0 0 33rem;
        }
    }

    &__slideshow {
        z-index: z(index, intro, slideshow);

        @include breakpoint($breakpoint-sm) {
            padding-top: $padding-rect-4-3;
            order: -1;            
            
            &:after {
            		display: block;
            		content: '';
            	}
        }

        @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
            flex: 1;
        }

        &-img {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            
            &.v--active {
                display: block;
            }

            &.v--in {
                z-index: 1;
            }

            &.v--out {
                z-index: 2;
            }
            
            @include breakpoint($breakpoint-sm) {
                margin-left: 1.75rem;
            }
        }

        &-img-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: none;

            svg {
                width: 100%;
                height: 100%;

                path {
                    fill: $color__white;
                }
            }
        }

        &-img-waves {
            display: none;
        }

        &-symbol {
            position: absolute;
            width: 3.25rem;
            height: 3.25rem;
            background-color: $color__pink;
            pointer-events: none;
            @include border-radius(50%);

            @include breakpoint($breakpoint-sm) {
                right: 1.75rem;
                top: -1.5rem;
            }

            @include breakpoint($breakpoint-md) {
                left: -1.5rem;
                bottom: 4rem;
            }

            @include breakpoint($breakpoint-lg) {
                left: -1.5rem;
                bottom: 5rem;
            }

            &-icon {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50%;
                height: auto;
                transform: translateX(-50%) translateY(-50%);

                svg {
                    width: 100%;
                    height: auto;

                    rect,
                    ellipse,
                    circle,
                    g,
                    path,
                    polygon {
                        fill: white;
                    }
                }

                &.v--innovate {
                    display: block;
                    width: 45%;
                    transform: translateX(-37%) translateY(-32%);
                }

                &.v--create {
                    width: 45%;
                    transform: translateX(-48%) translateY(-33%);
                }

                &.v--inspire {
                    width: 50%;
                    transform: translateX(-52%) translateY(-36%);
                }

                &.v--fire {
                    width: 55%;
                    transform: translateX(-50%) translateY(-38%);
                }

                &.v--water {
                    display: block;
                    width: 55%;
                    transform: translateX(-50%) translateY(-38%);
                }

                &.v--air {
                    width: 55%;
                    transform: translateX(-46%) translateY(-35%);
                }
            }
        }
    }

    &__section {
        display: none;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint($breakpoint-sm) {
            max-width: 30rem;
            padding: 3.25rem 1.75rem 2.5rem 1.75rem;
        }

        @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
            height: 100%;
            flex-direction: column;
        }

        &.v--active {
            @include breakpoint($breakpoint-sm) {
                display: block;
            }
            @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
                display: flex;
            }
        }

        &-txt {

            @include breakpoint($breakpoint-sm) {
                margin-bottom: 2rem;
            }

            @include breakpoint(($breakpoint-md, $breakpoint-lg)) {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @include breakpoint($breakpoint-md) {
                padding-left: 3rem;
                padding-right: 3rem;
            }

            @include breakpoint($breakpoint-lg) {
                padding-left: 5.5rem;
                padding-right: 5.5rem;
            }
        }

        &-title {

            @include breakpoint($breakpoint-sm) {
                padding-bottom: 0.5rem;
                margin-bottom: 1.5rem;
            }

            @include breakpoint($breakpoint-md) {
                padding-bottom: 0.5rem;
                margin-bottom: 2rem;
            }

            @include breakpoint($breakpoint-lg) {
                padding-bottom: 0.5rem;
                margin-bottom: 2.5rem;
            }
        }

        &-paragraph {
            display: flex;

            @include breakpoint($breakpoint-sm) {
                margin-bottom: 1.75rem;
            }
            
            @include breakpoint($breakpoint-md) {
                margin-bottom: 2.25rem;
            }

            @include breakpoint($breakpoint-lg) {
                margin-bottom: 3rem;
            }

            &-number {
                font-size: 90%;

                @include breakpoint($breakpoint-sm) {
                    display: none;
                }

                @include breakpoint($breakpoint-md) {
                    flex: 0 0 2rem;
                }

                @include breakpoint($breakpoint-lg) {
                    flex: 0 0 2.5rem;
                }

                span {
                    width: 100%;
                    color: $color__pink;
                    border-bottom: 1px $color__pink solid;
                    text-align: center;
                    padding-bottom: 0.5rem;
                }
            }

            p {
                flex: 1;
            }
        }
    }
    
    &__loader {
        width: $c-loader__size;
        position: absolute;
        top: 50vh;
        left: 50%;
        margin-top: -$c-loader__size / 2;
        margin-left: -$c-loader__size / 2;
       
        svg {
            width: 100%;
            height: auto;
        }
    }
    
    &.v--init &__sections,
    &.v--init &__slideshow,
    &__extra.v--init {
        opacity: 0;
    }
}