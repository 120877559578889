.c-section-img {

    @include breakpoint($breakpoint-md) {
        max-width: 49rem;
    }
    @include breakpoint($breakpoint-lg) {
        max-width: 60rem;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &-svg {
            display: block;
            width: 100%;
            height: auto;
            transform: translateY(-25%);
        }

        svg {
            width: 100%;
            height: auto;

            path {
                fill: white;
            }
        }
    }
}