.c-copyright {
    padding-right: 1.5rem;
    padding-top: 6rem;
    padding-bottom: 0.75rem;
    font-size: 75%;
    text-align: right;

    @include breakpoint($breakpoint-sm) {
        padding-top: 5rem;
        padding-bottom: 0.5rem;
        padding-right: 1.75rem;
    }
}