/**
 * Body
 */
$font__family: ('matter', sans-serif);
$font__family-default: sans-serif;
$font__style: normal;
$font__color: $color__txt;
$font__line-height: 1.7;
$font__size--sm: 1.15rem;
$font__size--md: 1.15rem;
$font__size--lg: 1.2rem;

/**
 * Normal
 */
$font__weight: 300;
$font__weight-default: normal;

/**
 * Bold
 */
$font-bold__weight: 700;
$font-bold__weight-default: bold;

/**
 * Uppercase
 */
$font-uppercase__letter-spacing: 0.075em;

/**
 * H1
 */
$font-h1__family: ('sora', sans-serif);
$font-h1__family-default: sans-serif;
$font-h1__weight: 900;
$font-h1__weight-default: bold;
$font-h1__style: normal;
$font-h1__color: $color__title;
$font-h1__letter-spacing: normal;
$font-h1__line-height: 0.9;
$font-h1__size--sm: 2.375rem;
$font-h1__size--md: 3rem;
$font-h1__size--lg: 3.5rem;

/**
 * H2
 */
$font-h2__family: ('sora', sans-serif);
$font-h2__family-default: sans-serif;
$font-h2__weight: 700;
$font-h2__weight-default: bold;
$font-h2__style: normal;
$font-h2__color: $color__title;
$font-h2__letter-spacing: normal;
$font-h2__line-height: 0.9;
$font-h2__size--sm: 2.375rem;
$font-h2__size--md: 3rem;
$font-h2__size--lg: 3.625rem;

/**
 * H3
 */
$font-h3__family: ('sora', sans-serif);
$font-h3__family-default: sans-serif;
$font-h3__weight: 300;
$font-h3__weight-default: normal;
$font-h3__style: normal;
$font-h3__color: $color__title;
$font-h3__letter-spacing: normal;
$font-h3__line-height: 1.2;
$font-h3__size--sm: 1.5rem;
$font-h3__size--md: 1.625rem;
$font-h3__size--lg: 1.625rem;

/**
 * H4
 */
$font-h4__family: ('sora', sans-serif);
$font-h4__family-default: sans-serif;
$font-h4__weight: 700;
$font-h4__weight-default: bold;
$font-h4__style: normal;
$font-h4__color: $color__title;
$font-h4__letter-spacing: normal;
$font-h4__line-height: 1.2;
$font-h4__size--sm: 1.375rem;
$font-h4__size--md: 1.625rem;
$font-h4__size--lg: 1.875rem;

/**
 * H5
 */
$font-h5__family: ('sora', sans-serif);
$font-h5__family-default: sans-serif;
$font-h5__weight: 300;
$font-h5__weight-default: normal;
$font-h5__style: normal;
$font-h5__color: $color__white;
$font-h5__letter-spacing: normal;
$font-h5__line-height: 1.2;
$font-h5__size--sm: 1.25rem;
$font-h5__size--md: 1.625rem;
$font-h5__size--lg: 1.625rem;

/**
 * DT
 */
$font-dt__family: ($font__family, $font__family-default);
$font-dt__family-default: $font__family-default;
$font-dt__weight: 700;
$font-dt__weight-default: normal;
$font-dt__style: normal;
$font-dt__color: $color__txt-dark;
$font-dt__letter-spacing: 0.075em;
$font-dt__line-height: 1.2;
$font-dt__size--sm: 0.65rem;
$font-dt__size--md: 0.75rem;
$font-dt__size--lg: 0.75rem;

/**
 * DL
 */
$font-dl__family: ('graphik', $font__family-default);
$font-dl__family-default: $font__family-default;
$font-dl__weight: 200;
$font-dl__weight-default: normal;
$font-dl__style: normal;
$font-dl__color: $color__pink;
$font-dl__letter-spacing: -0.025em;
$font-dl__line-height: 1.2;
$font-dl__size--sm: 3rem;
$font-dl__size--md: 3rem;
$font-dl__size--lg: 3.75rem;

/**
 * Banner
 */
$o-heading-banner-font__family: ('sora', sans-serif);
$o-heading-banner-font__family-default: sans-serif;
$o-heading-banner-font__weight: 700;
$o-heading-banner-font__weight-default: bold;
$o-heading-banner-font__style: normal;
$o-heading-banner-font__color: $color__blue-light;
$o-heading-banner-font__letter-spacing: -0.035em;
$o-heading-banner-font__line-height: 1.35;
$o-heading-banner-font__size--sm: 8rem;
$o-heading-banner-font__size--md: 12.5rem;
$o-heading-banner-font__size--lg: 15rem;