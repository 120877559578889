@import 'ceaser-easing';

/**
 * Font family
 */
@mixin font-family($family-loaded, $family-default: '', $class-loaded: 'fonts-loaded') {
    @if ($family-default == '') {
        font-family: $family-loaded;
    } @else {
        font-family: $family-default;

        .#{$class-loaded} & {
            font-family: $family-loaded;
        }
    }
}

/**
 * Font weight
 */
@mixin font-weight($weight-loaded, $weight-default: '', $class-loaded: 'fonts-loaded') {
    @if ($weight-default == '') {
        font-weight: $weight-loaded;
    } @else {
        font-weight: $weight-default;

        .#{$class-loaded} & {
            font-weight: $weight-loaded;
        }
    }
}

/**
 * Corners
 */
@mixin border-radius($radii) {
    border-radius: $radii;
}

@mixin border-radius-left($radii) {
    border-bottom-left-radius: $radii;
    border-top-left-radius: $radii;
}

@mixin border-radius-right($radii) {
    border-bottom-right-radius: $radii;
    border-top-right-radius: $radii;
}

@mixin border-radius-top($radii) {
    border-top-right-radius: $radii;
    border-top-left-radius: $radii;
}

@mixin border-radius-bottom($radii) {
    border-bottom-right-radius: $radii;
    border-bottom-left-radius: $radii;
}

@mixin border-radius-top-left($radii) {
    border-top-left-radius: $radii;
}

@mixin border-radius-top-right($radii) {
    border-top-right-radius: $radii;
}

@mixin border-radius-bottom-left($radii) {
    border-bottom-left-radius: $radii;
}

@mixin border-radius-bottom-right($radii) {
    border-bottom-right-radius: $radii;
}

/**
 * Box Shadow
 */
@mixin box-shadow($x, $y, $blur, $color, $inset: false) {
    @if $inset {
        box-shadow:inset $x $y $blur $color;
    } @else {
        box-shadow: $x $y $blur $color;
    }
}

/**
 * Keyframes
 */
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

/**
 * Section
 */
@mixin section($width: 100%) {
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
}

/**
 * Clearfix
 */
@mixin clearfix() {
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
}

/**
 * Grid
 */
@mixin grid() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/**
 * Rows
 */
@mixin rows($rows, $breakpoints) {
    $nb_cols: map-get($rows, cols);
    $class: --#{$nb_cols};

    // Suffix
    @if map-has-key($rows, suffix) and map-get($rows, suffix) != '' {
        $suffix: map-get($rows, suffix);
        $class: #{$class}-#{$suffix};
    }

    .o-row {

        @each $breakpoint-name, $breakpoint in $breakpoints {
            @include breakpoint($breakpoint) {

                // Align center
                &--center,
                &--center\@#{$breakpoint-name} {
                    justify-content: flex-center;
                }

                // Align end
                &--end,
                &--end\@#{$breakpoint-name} {
                    justify-content: flex-end;
                }

                // No Wrap
                &--nowrap,
                &--nowrap\@#{$breakpoint-name} {
                    flex-wrap: nowrap;
                }

                // Col pushed at the beginning
                > .o-col--start,
                > .o-col--start\@#{$breakpoint-name} {
                    order: -1;
                }

                // Col pushed at the beginning
                > .o-col--end,
                > .o-col--end\@#{$breakpoint-name} {
                    order: 1;
                }

                // Spans
                @if map-has-key($rows, spans) {

                    // Map Spans
                    $rows-spans: (
                        cols: $nb_cols,
                        gutters: map-get-deep($rows, $breakpoint-name, gutters),
                        max-width: map-get-deep($rows, $breakpoint-name, max-width),
                        spans: map-get($rows, spans)
                    );

                    &#{$class},
                    &#{$class}\@#{$breakpoint-name} {
                        @include row-cols($rows-spans);
                        @include row-cols($rows-spans, '#{$breakpoint-name}');
                    }
                }

                // Offsets
                @if map-has-key($rows, offsets) {

                    // Map Offsets
                    $rows-offsets: (
                        cols: $nb_cols,
                        gutters: map-get-deep($rows, $breakpoint-name, gutters),
                        offsets: map-get($rows, offsets)
                    );

                    &#{$class},
                    &#{$class}\@#{$breakpoint-name} {
                        @include row-offsets($rows-offsets);
                        @include row-offsets($rows-offsets, '#{$breakpoint-name}');
                    }
                }

            }
        }
    }
}

/**
 * Row - Cols
 */
@mixin row-cols($cols, $breakpoint: '') {
    $nb_cols: map-get($cols, cols);
    $gutters: map-get($cols, gutters);
    $max_width: map-get($cols, max-width);
    $spans: map-get($cols, spans);

    @each $span in $spans {

        // No Breakpoint
        @if ($breakpoint == '') {
            @if ($span == 1) {
                > .o-col {
                    @include row-col($span, $nb_cols, $gutters);
                }
            } @else {
                > .o-col--#{$span} {
                    @include row-col($span, $nb_cols, $gutters, true, $max_width);
                }
            }

        // Breakpoint
        } @else {
            @if ($span == 1) {
                > .o-col\@#{$breakpoint} {
                    @include row-col($span, $nb_cols, $gutters);
                }
            } @else {
                > .o-col--#{$span}\@#{$breakpoint} {
                    @include row-col($span, $nb_cols, $gutters, true, $max_width);
                }
            }
        }
    }
}

/**
 * Row - Col
 */
@mixin row-col($span, $nb_cols, $gutter: 0rem, $margins: true, $max_width: 100%) {

    $percent_width: 100% / $nb_cols;

    $nb_gutters_span: $span - 1;
    $nb_gutters_total: $nb_cols - 1;

    $gutter_ratio: $nb_gutters_total / $nb_cols;
    $gutters_gutter_ratio: $gutter * $gutter_ratio;

    // Fraction
    @if ($span != $nb_cols) {

        // Gutter
        @if ($gutter > 0rem) {

            // Auto
            @if ($span == auto) {
                flex: 1;

            // Max
            } @else if ($span == max) {
                flex: 0 1 calc(#{$max_width} - #{$gutter} / 2);

            // Fixed
            } @else {
                flex: 0 0 auto;

                $gutters_span: $nb_gutters_span * $gutter;

                // Span
                @if ($span > 1) {
                    width: calc(#{$span} * (#{$percent_width} - #{$gutters_gutter_ratio}) + #{$gutters_span});

                // No Span
                } @else {
                    width: calc(#{$percent_width} - #{$gutters_gutter_ratio});
                }
            }

            // Margin
            @if ($margins == true) {
                margin-left: $gutter;
                &:first-child {
                    margin-left: 0;
                }
            }

        // No gutter
        } @else {

            // Auto
            @if ($span == auto) {
                flex: 1;

            // Max
            } @else if ($span == max) {
                flex: 0 1 $max_width;

            // Fixed
            } @else {
                flex: 0 0 auto;
                width: $span * $percent_width;
            }
        }

    // 100%
    } @else {
        width: 100%;
    }
}

/**
 * Row - Offset
 */
@mixin row-offset($span, $nb_cols, $gutter: 0rem) {

    $percent_width: 100% / $nb_cols;

    $nb_gutters_span: $span - 1;
    $nb_gutters_total: $nb_cols - 1;

    $gutter_ratio: $nb_gutters_total / $nb_cols;
    $gutters_gutter_ratio: $gutter * $gutter_ratio;

    // Gutter
    @if ($gutter > 0rem) {
        $gutters_span: $nb_gutters_span * $gutter + $gutter * 2;

        // Span
        @if ($span > 1) {
            margin-left: calc(#{$span} * (#{$percent_width} - #{$gutters_gutter_ratio}) + #{$gutters_span});

        // No Span
        } @else {
            $gutters_span_ratio: $gutters_span - $gutters_gutter_ratio;
            margin-left: calc(#{$percent_width} + #{$gutters_span_ratio});
        }

    // No gutter
    } @else {
        margin-left: $span * $percent_width;
    }
}

/**
 * Row - Offsets
 */
@mixin row-offsets($cols, $breakpoint: '') {
    $nb_cols:   map-get($cols, cols);
    $gutters:   map-get($cols, gutters);
    $offsets:   map-get($cols, offsets);

    @each $offset in $offsets {

        // No Breakpoint
        @if ($breakpoint == '') {
            @if ($offset == 1) {
                > .o-offset {
                    @include row-offset($offset, $nb_cols, $gutters);
                }
            } @else {
                > .o-offset--#{$offset} {
                    @include row-offset($offset, $nb_cols, $gutters);
                }
            }

        // Breakpoint
        } @else {
            @if ($offset == 1) {
                > .o-offset\@#{$breakpoint} {
                    @include row-offset($offset, $nb_cols, $gutters);
                }
            } @else {
                > .o-offset--#{$offset}\@#{$breakpoint} {
                    @include row-offset($offset, $nb_cols, $gutters);
                }
            }
        }
    }
}

/**
 * Grids
 */
@mixin grids($grids, $breakpoints) {
    $nb_cols: map-get($grids, cols);
    $class: --#{$nb_cols};
    $version: false;

    // Suffix
    @if map-has-key($grids, suffix) and map-get($grids, suffix) != '' {
        $suffix: map-get($grids, suffix);
        $class: #{$class}-#{$suffix};
    }

    // Version
    @if map-has-key($grids, version) {
        $version: map-get($grids, version);
    }

    .o-grid {

        @each $breakpoint-name, $breakpoint in $breakpoints {
            @include breakpoint($breakpoint) {

                // Map Spans
                $grids-spans: (
                    cols: $nb_cols,
                    gutters: map-get-deep($grids, $breakpoint-name, gutters)
                );

                // Version
                @if ($version) {
                    &.v#{$class},
                    &.v#{$class}\@#{$breakpoint-name} {
                        @include grid-cols($grids-spans);
                    }
                // Explicit
                } @else {
                    &#{$class},
                    &#{$class}\@#{$breakpoint-name} {
                        @include grid-cols($grids-spans);
                    }
                }
            }
        }
    }
}

/**
 * Grid - Cols
 */
@mixin grid-cols($cols) {
    $nb_cols: map-get($cols, cols);
    $gutters: map-get($cols, gutters);

    > .o-col {
        @include grid-col($nb_cols, $gutters);
    }
}

/**
 * Grid - Col
 */
@mixin grid-col($nb_cols, $gutter: 0rem) {

    $percent_width: 100% / $nb_cols;

    $nb_gutters_total: $nb_cols - 1;

    $gutter_ratio: $nb_gutters_total / $nb_cols;
    $gutters_gutter_ratio: $gutter * $gutter_ratio;

    // Gutters
    @if ($gutter > 0rem) {
        flex: 0 0 auto;
        width: calc(#{$percent_width} - #{$gutters_gutter_ratio});
        margin-left: $gutter;

        &:nth-of-type(#{$nb_cols}n+1) {
            margin-left: 0;
        }

        &:nth-of-type(n+#{$nb_cols + 1}) {
            margin-top: $gutter;
        }

    // No gutter
    } @else {
        flex: 0 0 auto;
        width: $percent_width;
    }
}

/**
 * Background Image
 */
@mixin background-image($url, $repeat: no-repeat, $position-horizontal: center, $position-vertical: center, $size: auto) {
    background-image: url($url);
    background-repeat: $repeat;
    background-position: $position-horizontal $position-vertical;
    background-size: $size;
}

/**
 * Background Position
 */
@mixin background-position($position-horizontal: center, $position-vertical: center) {
    background-position: $position-horizontal $position-vertical;
}

/**
 * Background Color opacity
 */
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color;                 // The Fallback
    background: rgba($color, $opacity);
}

/**
 * Opacity
 */
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); // IE8
}

/**
 * Children from the first to `$num`
 */
@mixin first($num) {
    @if $num == 1 {
        &:first-child {
            @content;
        }
    } @else {
        &:nth-child(-n + #{$num}) {
            @content;
        }
    }
}

/**
 * Children from the last to `$num`
 */
@mixin last($num) {
    &:nth-last-child(-n + #{$num}) {
        @content;
    }
}

/**
 * Children after the first to `$num`
 */
@mixin after-first($num) {
    &:nth-child(n + #{$num + 1}) {
        @content;
    }
}

/**
 * Children before `$num` from the last
 */
@mixin from-end($num) {
    &:nth-last-child(#{$num}) {
        @content;
    }
}

/**
 * Children between `$first` and `$last`
 */
@mixin between($first, $last) {
    &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}

/**
 * Even children between `$first` and `$last`
 */
@mixin even-between($first, $last) {
    &:nth-child(even):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}

/**
 * Odd children between `$first` and `$last`
 */
@mixin odd-between($first, $last) {
    &:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}

/**
 * All `$num` children between `$first` and `$last`
 */
@mixin n-between($num, $first, $last) {
    &:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}

/**
 * All children but `$num`
 */
@mixin all-but($num) {
    &:not(:nth-child(#{$num})) {
        @content;
    }
}

/**
 * Children each `$num`
 */
@mixin each($num) {
    &:nth-child(#{$num}n) {
        @content;
    }
}

/**
 * Select the first of `$limit` items. It will not work if there is not as much as item as you set in `$limit`
 */
@mixin first-of($limit) {
    &:nth-last-child(#{$limit}):first-child {
        @content;
    }
}

/**
 * Select the last of `$limit` items. It will not work if there is not as much as item as you set in `$limit`
 */
@mixin last-of($limit) {
    &:nth-of-type(#{$limit}):nth-last-of-type(1) {
        @content;
    }
}

/**
 * Select every items if there is at least `$num` items. It will not if there is not as much as item as you set in `$num`
 */
@mixin at-least($num) {
    $selector: &;
    $child: nth(nth($selector, -1), -1);
    &:nth-last-child(n + #{$num}),
    &:nth-last-child(n + #{$num}) ~ #{$child} {
        @content;
    }
}

/**
 * Select every items if there is at most `$num` items. It will not if there is not as much as item as you set in `$num`
 */
@mixin at-most($num) {
    $selector: &;
    $child: nth(nth($selector, -1), -1);
    &:nth-last-child(-n + #{$num}):first-child,
    &:nth-last-child(-n + #{$num}):first-child ~ #{$child} {
        @content;
    }
}

/**
 * Select every items only if there is between `$min` and `$max` items
 */
@mixin in-between($min, $max) {
    $selector: &;
    $child: nth(nth($selector, -1), -1);
    &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
    &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {
        @content;
    }
}

/**
 * Select the first exact child
 */
@mixin first-child() {
    &:first-of-type {
        @content
    }
}

/**
 * Select the last exact child
 */
@mixin last-child() {
    &:last-of-type {
        @content
    }
}

/**
 * Select all even children
 */
@mixin even() {
    &:nth-child(even) {
        @content;
    }
}

/**
 * Select all odd children
 */
@mixin odd() {
    &:nth-child(odd) {
        @content;
    }
}

/**
 * Select only the first and last child
 */
@mixin first-last() {
    &:first-child,
    &:last-child {
        @content;
    }
}

/**
 * Select the child if it’s unique
 */
@mixin unique() {
    &:only-child {
        @content;
    }
}

/**
 * Select the child if it’s not unique
 */
@mixin not-unique() {
    &:not(:only-child) {
        @content;
    }
}