body {
    @include font-family($font__family, $font__family-default);
    @include font-weight($font__weight, $font__weight-default);
    font-style: $font__style;
    color: $font__color;
    line-height: $font__line-height;
    letter-spacing: 0.025em;

    @include breakpoint($breakpoint-sm) {
        font-size: $font__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font__size--lg;
    }
}

h1,
.h1 {
    @include font-family($font-h1__family, $font-h1__family-default);
    @include font-weight($font-h1__weight, $font-h1__weight-default);
    font-style: $font-h1__style;
    color: $font-h1__color;
    letter-spacing: $font-h1__letter-spacing;
    line-height: $font-h1__line-height;

    @include breakpoint($breakpoint-sm) {
        font-size: $font-h1__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font-h1__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font-h1__size--lg;
    }
}

h2 {
    @include font-family($font-h2__family, $font-h2__family-default);
    @include font-weight($font-h2__weight, $font-h2__weight-default);
    font-style: $font-h2__style;
    color: $font-h2__color;
    line-height: $font-h2__line-height;
    letter-spacing: $font-h2__letter-spacing;

    @include breakpoint($breakpoint-sm) {
        font-size: $font-h2__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font-h2__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font-h2__size--lg;
    }
}

h3,
.h3 {
    @include font-family($font-h3__family, $font-h3__family-default);
    @include font-weight($font-h3__weight, $font-h3__weight-default);
    font-style: $font-h3__style;
    color: $font-h3__color;
    line-height: $font-h3__line-height;
    letter-spacing: $font-h3__letter-spacing;

    @include breakpoint($breakpoint-sm) {
        font-size: $font-h3__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font-h3__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font-h3__size--lg;
    }
}

h4,
.h4 {
    @include font-family($font-h4__family, $font-h4__family-default);
    @include font-weight($font-h4__weight, $font-h4__weight-default);
    font-style: $font-h4__style;
    color: $font-h4__color;
    line-height: $font-h4__line-height;
    letter-spacing: $font-h4__letter-spacing;

    @include breakpoint($breakpoint-sm) {
        font-size: $font-h4__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font-h4__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font-h4__size--lg;
    }
}

h5,
.h5 {
    @include font-family($font-h5__family, $font-h5__family-default);
    @include font-weight($font-h5__weight, $font-h5__weight-default);
    font-style: $font-h5__style;
    color: $font-h5__color;
    line-height: $font-h5__line-height;
    letter-spacing: $font-h5__letter-spacing;

    @include breakpoint($breakpoint-sm) {
        font-size: $font-h5__size--sm;
    }

    @include breakpoint($breakpoint-md) {
        font-size: $font-h5__size--md;
    }

    @include breakpoint($breakpoint-lg) {
        font-size: $font-h5__size--lg;
    }
}

a {
    color: $color__txt;

    &.v--txt {
        color: $color__txt;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: $color__txt;

        .no-touch &:hover {
            color: $color__txt-dark;
            text-decoration-color: $color__txt-dark;
        }
    }
}