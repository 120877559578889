.u-display {

    &--block {
    	display: block;
    }

    &--table {
    	display: table;
    }

    &--flex {
    	display: flex;
    }
}