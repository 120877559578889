.js-textReveal {

    span.js-textReveal__animation-wrapper {
        display: inline-block;
        white-space: pre-wrap;
        vertical-align: bottom;

        sub {
            display: inline-block;
        }
    }

    &.v--animation-up {
        span.js-textReveal__animation-wrapper {
            overflow: hidden;
        }
    }

    /*
     * JS
     */
    &.v--js {
        opacity: 0;
    }
}