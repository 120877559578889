.o-heading {

    &-banner {
        @include font-family($o-heading-banner-font__family, $o-heading-banner-font__family-default);
        @include font-weight($o-heading-banner-font__weight, $o-heading-banner-font__weight-default);
        font-style: $o-heading-banner-font__style;
        color: $o-heading-banner-font__color;
        letter-spacing: $o-heading-banner-font__letter-spacing;
        line-height: $o-heading-banner-font__line-height;

        &.v--blue-light {
            color: $color__blue-light;
        }

        &.v--pink {
            color: $color__pink;
        }

        @include breakpoint($breakpoint-sm) {
            font-size: $o-heading-banner-font__size--sm;
        }

        @include breakpoint($breakpoint-md) {
            font-size: $o-heading-banner-font__size--md;
        }

        @include breakpoint($breakpoint-lg) {
            font-size: $o-heading-banner-font__size--lg;
        }
    }
}